import React from 'react'
import Accordion from '../../components/accordion'
import Layout from '../../layout'
import ServicesTitle from '../../components/services/index'

const Services = () => {
  return (
    <Layout>
      <ServicesTitle/>
      <Accordion />
    </Layout>
  )
}

export default Services