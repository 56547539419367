import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/contact.css";
import data from "../../data/lang/tr.json";
import Footer from "../../layout/footer/index";
import axios from "axios";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";

const ContactSection = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // Add a listener to ensure the page scrolls to the top on page reload
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const form = useRef(); // Add missing 'useRef'
  const [isSuccess, setIsSuccess] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [tel, setTel] = useState("");
  const [massage, setMessage] = useState("");

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const encryptedText = CryptoJS.AES.encrypt(
      uuidv4(),
      process.env.REACT_APP_SSK
    ).toString();

    const FD = new FormData(form.current);
    FD.append("accessToken", encryptedText);

    const obj = {
      message: FD.get("message"),
      userInfo: "Osiads website",
      from: FD.get("userInfo"),
      subject: "Osi Ads'ten mailiniz var.",
      tel: FD.get("tel"),
      ownerName: "osicrewdeveloper@gmail.com",
      to: "osicrewdeveloper@gmail.com",
      host: "smtp.gmail.com",
      service: "gmail",
      accessToken: encryptedText,
    };
    try {
      const res = await axios.post("https://nodemailer.3wweb.org/", obj, {
        auth: {
          username: "OSICrew_Node_Mailler",
          password: "1786oSi?173",
        },
      });
      if (res) {
        console.log(res);
        setIsSuccess(true);
        toast.success("Mesajınız başarıyla iletilmiştir.");
        await new Promise((resolve) => setTimeout(resolve, 2000));        

        // Clear form inputs after a successful submission
        setUserInfo("");
        setTel("");
        setMessage("");
      }
    } catch (error) {
      console.log(error);
      toast.error("Mesajınız iletilirken bir hata oluştu.");
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  };

  return (
    <>
      <div className="contact d-flex justify-content-center pb-5">
        <h1 className="contact_1 me-3">{data["contact-big-t1"]}</h1>
        <h1 className="contact_1">{data["contact-big-t2"]}</h1>
        <h1 className="contact_2 me-3">{data["contact-big-t1"]}</h1>
        <h1 className="contact_2">{data["contact-big-t2"]} </h1>
        <h1 className="contact_1 me-3">{data["contact-big-t1"]}</h1>
        <h1 className="contact_1">{data["contact-big-t2"]}</h1>
      </div>

      <div className="d-flex justify-content-center  position-relative">
        <ToastContainer />
        <img
          alt="letter"
          className="letter_front"
          src="/assets/img/zarf_no_bg.png"
        />
        <img
          className="letter_cover"
          src="/assets/img/kapak.png"
          alt="mektup_kapak"
        />
        <img
          className="letter_background"
          src="/assets/img/zarf_bg.png"
          alt="mektup_bg"
        />

        <form
          onSubmit={onSubmitHandler}
          ref={form}
          className="form position-absolute"
        >
          <div className="d-flex justify-content-center form-top contact_form">
            <div className="form_top_input d-flex flex-column justify-content-center align-items-center form-part  letter_form">
              <h3>{data["contact_form_title"]}</h3>
              <input
                name="userInfo"
                required
                type="email"
                placeholder={data["contact_form_email"]}
              />
              <input
                name="tel"
                required
                type="tel"
                placeholder={data["contact_form_phone"]}
              />
            </div>
          </div>
          <div className="contact_form d-flex justify-content-center ">
            <div className="form_top_input d-flex flex-column justify-content-center align-items-center form-part-2 letter_form">
              <input
                name="message"
                required
                className="message"
                type="message"
                placeholder={data["contact_form_message"]}
              />
              <button
                type="submit"
                onSubmit={onSubmitHandler}
                className="form-btn"
              >
                {data["contact_form_btn"]}
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="contact_links d-flex justify-content-around align-items-center mb-5 ">
        <div className="d-flex align-items-center">
          <div className="icon_div d-flex justify-content-center align-items-center me-3">
            <img src="/assets/img/icon/icons_arrow-back.png" alt="arrow" />
          </div>
          <div className="contact_link_icons">
            <h5>{data["footer-contact-1"]}</h5>
            <a
              href="https://maps.app.goo.gl/PfJNncokkmN6XTMG9"
              target="_blank"
              rel="noreferrer"
            >
              {data["contact_adress_1"]}
              <br /> {data["contact_adress_2"]}
            </a>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <div className="icon_div d-flex justify-content-center align-items-center me-3">
            <img src="/assets/img/icon/icon_phone.png" alt="phone_icon"></img>
          </div>

          <div className="contact_link_icons">
            <h5>{data["footer-contact-3"]}</h5>
            <a href="tel:+90344902100">{data["footer-contact-4"]}</a>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="icon_div d-flex justify-content-center align-items-center me-3">
            <img src="/assets/img/icon/icon-mail.png" alt="mail_icon"></img>
          </div>
          <div className="contact_link_icons">
            <h5>{data["footer-contact-5"]}</h5>
            <a href="mailto:info@osireklamajansi.com">
              {data["footer-contact-6"]}
            </a>
          </div>
        </div>
      </div>

      <div className="contact_icons d-flex align-items-center justify-content-center">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/osireklamajansi/"
        >
          <div className="insta_logo"></div>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/company/osicrew/"
        >
          <div className="linkedin_logo"></div>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/i/flow/login?redirect_after_login=%2Fosireklamajansi"
        >
          <div className="x_logo"></div>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/people/osi-reklam-ajans%C4%B1/100063710493177/"
        >
          <div className="facebook_logo"></div>
        </a>
      </div>

      <Footer />
    </>
  );
};

export default ContactSection;
