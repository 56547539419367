import React from 'react'
import ContactSection from '../../components/contact'
import Layout from '../../layout'

const Contact = () => {
  return (
    <Layout>
    <ContactSection/>
</Layout>  )
}

export default Contact