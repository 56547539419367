/* import React from 'react'
import Layout from '../../layout'
import Footer from '../../layout/footer'
import "../../assets/css/404.css"
const NotFound = () => {

    const app = document.getElementsByClassName('not_found');
    const text = 'Your Text Here';
    const delay = 1; // Delay in seconds

    for (let i = 0; i < text.length; i++) {
      const span = document.createElement('span');
      span.textContent = text[i];
      span.classList.add('animated-text');
      span.style.animation = `notFound ${text.length * delay}s ease-in-out infinite`;
      span.style.animationDelay = `${i + delay}s`;
      app.appendChild(span);
    }
    
  return (
    <Layout>
        <div className='not_found_div'>

        <h1 className='not_found'>
        <span>#</span>
        <span>4</span>
        <span>0</span>
        <span>4</span>
        </h1>
        <h2 className='notFound_text'>Sayfa Bulunamadı.</h2> 
        </div>
        <Footer/>
    </Layout>
  )
}

export default NotFound  */


import React, { useEffect, useState } from 'react';
import Layout from '../../layout';
import Footer from '../../layout/footer';
import '../../assets/css/404.css';

const NotFound = () => {
  const text = '#404';
  const delay = .7; // Delay in seconds
  const [spans, setSpans] = useState([]);

  useEffect(() => {
    const spanElements = [];
    for (let i = 0; i < text.length; i++) {
      spanElements.push(
        <span
          key={i}
        //   className='not_found'
          style={{
            animation: `notFound ${text.length * delay}s ease-in-out infinite`,
            animationDelay: `${i * delay}s`,
          }}
        >
          {text[i]}
        </span>
      );
    }
    setSpans(spanElements);
  }, []);

  return (
    <Layout>
      <div className='not_found_div'>
        <div className='background'/>
        <h1 className='not_found'>
          {spans}
        </h1>
        <h2 className='notFound_text'>Sayfa Bulunamadı.</h2>
      </div>
      <Footer />
    </Layout>
  );
};

export default NotFound;


