import React, { useEffect } from "react";
import "../../assets/css/about.css";
import data from "../../data/lang/tr.json";
import Footer from "../../layout/footer";

const AboutSection = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
    
    // Add a listener to ensure the page scrolls to the top on page reload
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  return (
    <>
      <div className="about d-flex justify-content-center">
        <h1 className="about_1">{data["about-big-title"]} </h1>
        <h1 className="about_2"> {data["about-big-title"]} </h1>
        <h1 className="about_1"> {data["about-big-title"]}</h1>
      </div>

      <section id="aboutSection">
        <div className="content">
          <h2>{data["about_title"]}</h2>
          <br />
          <p>{data["about_content_1"]}</p>
          <p>{data["about_content_2"]}</p>
        </div>

        <section class="wrapper">
          <div class="ziyaretciler">
            <img src="/assets/img/about/ziyaretciler.png" alt="ziyaretciler" />
          </div>
          <div className="grafikler">
            {/*   <img src="/assets/img/about/artıs_chart.png" alt="artıs grafigi" />
            <img
              src="/assets/img/about/gelir_grafigi.png"
              alt="gelir grafigi"
            /> */}
            <img src="/assets/img/about/2.grup.png" alt="grafikler_2" />
          </div>
          <div className="grafikler_2 ">
            <img src="/assets/img/about/3.grup.png" alt="grafikler_3" />
          </div>
        </section>
      </section>
      <Footer />

    </>
  );
};

export default AboutSection;
