import React from 'react'
import Header from "./header/index"
// import Footer from "./footer/index"


function Layout({ children }) {
    return (
        <div>
            <Header />
            {children}
            {/* {<SideLeft/>}
            {<SideRight/>} */}
            {/* <Footer /> */}

        </div>
    )
}

export default Layout