import data from "../../data/lang/tr.json";
import "../../assets/css/layout/style.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const Header = () => {
  function toTop() {
    window.scrollTo({ top: 0, behavior: "instant" });
    document.body.style.overflow = "scroll";
  }

  const location = useLocation();

  const [menuVisible, setMenuVisible] = useState(false);

  function toggleMenu() {
    setMenuVisible(!menuVisible);

    if (!menuVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }

  return (
    <>
      <nav className="header-list col-12 ">
        <div className="container d-flex justify-content-center position-relative header">
          <div className="col-xl-6 header_img">
            <Link onClick={toTop} to={"/"}>
              <img
                className=""
                src="/assets/logo/osiads.png"
                alt="osicrew logo"
              />
            </Link>
          </div>
          <ul className="d-flex justify-content-around col-xl-6 col-sm-10 col header_links">
            <Link onClick={toTop} to={"/services"}>
              <li
                className={
                  location.pathname === "/services" ? "active_link" : ""
                }
              >
                {data["header-2"]}
              </li>
            </Link>
            <Link onClick={toTop} to={"/about"}>
              <li
                className={location.pathname === "/about" ? "active_link" : ""}
              >
                {data["header-3"]}
              </li>
            </Link>
            <Link onClick={toTop} to={"/contact"}>
              <li
                className={
                  location.pathname === "/contact" ? "active_link" : ""
                }
              >
                {data["header-4"]}
              </li>
            </Link>
          </ul>

          {/* phone  */}

          {/* <button  onClick={toggleMenu} className="text-light">
          </button> */}
        </div>
        <div className="menu_toggle">
          <input type="checkbox" id="checkbox" />
          <label onClick={toggleMenu} for="checkbox" class="toggle">
            <div class="bars" id="bar1"></div>
            <div class="bars" id="bar2"></div>
            <div class="bars" id="bar3"></div>
          </label>
        </div>

        {/* {menuVisible && ( */}
        <div className={`menu_phone ${menuVisible ? "menu_phone_open" : ""}`}>
          <ul className="d-flex col-xl-6 col-sm-10 col menu_toggle_phone">
            <Link onClick={toTop} to={"/home"}>
              <li
                className={location.pathname === "/home" ? "active_link" : ""}
              >
                {data["header-1"]}
              </li>
            </Link>
            <Link onClick={toTop} to={"/services"}>
              <li
                className={
                  location.pathname === "/services" ? "active_link" : ""
                }
              >
                {data["header-2"]}
              </li>
            </Link>
            <Link onClick={toTop} to={"/about"}>
              <li
                className={location.pathname === "/about" ? "active_link" : ""}
              >
                {data["header-3"]}
              </li>
            </Link>
            <Link onClick={toTop} to={"/contact"}>
              <li
                className={
                  location.pathname === "/contact" ? "active_link" : ""
                }
              >
                {data["header-4"]}
              </li>
            </Link>
          </ul>
          <ul className="menu_icons d-flex justify-content-center align-items-center ">
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/osireklamajansi/"
              >
                <img
                  src="/assets/img/icon/instagram.png"
                  alt="instagram_icon"
                />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/osicrew/"
              >
                <img src="/assets/img/icon/linkedin.png" alt="linkedin_icon" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://osicrew.com/"
              >
                <img src="/assets/img/icon/osi.png" alt="osi_icon" />
              </a>
            </li>
          </ul>
        </div>
        {/* )} */}
      </nav>
    </>
  );
};

export default Header;
