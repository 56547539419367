import React, { useEffect } from "react";
import data from "../../data/lang/tr.json";
import "../../assets/css/accordion.css";
import Footer from "../../layout/footer";
const Accordion = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // Add a listener to ensure the page scrolls to the top on page reload
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  function AccordionItem({
    main_icon,
    id,
    title,
    img,
    img_alt,
    content_1,
    content_2,
    li_1,
    li_1_icon,
    li_2,
    li_2_icon,
    li_3,
    li_3_icon,
    li_4,
    li_4_icon,
    li_5,
    li_5_icon,
    li_6,
    li_6_icon,
  }) {
    return (
      <div className="accordion-item">
        <h2 className="accordion-header mt-3">
          <div className="d-flex">
            <div className="accordion_item_logo d-flex align-items-center">
              <img
                className=""
                src={main_icon}
                alt="services_main_icon"
                style={{ color: "#fff", marginRight: "7px" }}
              ></img>
            </div>

            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${id}`}
              aria-expanded="false"
              aria-controls={`collapse${id}`}
              style={{ position: "relative" }}
            >
              <h2 className="accordion_item_title">{title}</h2>
              <p className="col-7 content_1">{content_1}</p>
              <img
                className="star_icon"
                src="/assets/img/accordion/star.png"
                alt="star_icon"
                style={{ position: "absolute", top: "30%", right: 0 }}
              />
            </button>
          </div>
        </h2>
        <div
          id={`collapse${id}`}
          className="accordion-collapse collapse m-5"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body d-flex justify-content-center">
            <div className="col-7 ">
              {/* accordion-img */}
              <img src={img} alt={img_alt} />
            </div>
            <div className="accordion-context col-6">
              <div className="accordion-title">{content_2}</div>
              <div className="accordion-elements row mt-5">
                <div class="accordion-element col-6">
                  <div class="row mb-3 li_element ">
                    <div class="col-2 services_icon_div  text-center d-flex align-items-center">
                      <img className="services_icon" src={li_1_icon} alt="" />
                    </div>
                    <div class="col-10 ac_li_element">
                      <p>{li_1}</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-element col-6">
                  <div class="row mb-3 li_element">
                    <div class="col-2 services_icon_div  text-center d-flex align-items-center">
                      <img className="services_icon" src={li_2_icon} alt="" />
                    </div>
                    <div class="col-10 ac_li_element">
                      <p>{li_2}</p>
                    </div>
                  </div>
                </div>{" "}
                <div class="accordion-element col-6">
                  <div class="row mb-3 li_element">
                    <div class="col-2 services_icon_div  text-center d-flex align-items-center">
                      <img className="services_icon" src={li_3_icon} alt="" />
                    </div>
                    <div class="col-10 ac_li_element">
                      <p>{li_3}</p>
                    </div>
                  </div>
                </div>{" "}
                <div class="accordion-element col-6">
                  <div class="row mb-3 li_element">
                    <div class="col-2 services_icon_div  text-center d-flex align-items-center">
                      <img className="services_icon" src={li_4_icon} alt="" />
                    </div>
                    <div class="col-10 ac_li_element">
                      <p>{li_4}</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-element col-6">
                  <div class="row mb-3 li_element">
                    <div class="col-2 services_icon_div  text-center d-flex align-items-center">
                      <img className="services_icon" src={li_5_icon} alt="" />
                    </div>
                    <div class="col-10 ac_li_element">
                      <p>{li_5}</p>
                    </div>
                  </div>
                </div>
                {li_6.length > 0 ? (
                  <div class="accordion-element col-6">
                    <div class="row mb-3 li_element">
                      <div class="col-2 services_icon_div  text-center d-flex align-items-center">
                        <img className="services_icon" src={li_6_icon} alt="" />
                      </div>

                      <div class="col-10 ac_li_element">
                        <p>{li_6}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                <button className="startNow_button">
                  <a
                    href="https://forms.gle/K9M9cUf4iwADNES97"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data["accordion_startNow_btn"]}
                  </a>{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function AccordionItemSecond({
    main_icon,
    id,
    title,
    img,
    img_alt,
    content_1,
    content_2,
    li_1,
    li_1_icon,
    li_2,
    li_2_icon,
    li_3,
    li_3_icon,
    li_4,
    li_4_icon,
    li_5,
    li_5_icon,
    li_6,
    li_6_icon,
  }) {
    return (
      <div className="accordion-item">
        <h2 className="accordion-header mt-3">
          <div className="d-flex">
            <div className="accordion_item_logo d-flex align-items-center">
              <img
                src={main_icon}
                alt="services_main_icon"
                style={{ color: "#fff", marginRight: "7px" }}
              ></img>
            </div>
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${id}`}
              aria-expanded="false"
              aria-controls={`collapse${id}`}
              style={{ position: "relative" }}
            >
              <h2 className="accordion_item_title">{title}</h2>
              <p className="col-7 content_1 ">{content_1}</p>
              <img
                className="star_icon"
                src="/assets/img/accordion/star.png"
                alt="star_icon"
                style={{ position: "absolute", top: "30%", right: 0 }}
              ></img>
            </button>
          </div>
        </h2>
        <div
          id={`collapse${id}`}
          className="accordion-collapse collapse m-5"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body accordion-body_second d-flex justify-content-center">
            <div className="accordionSecond-context col-6">
              <div className="accordion-title">{content_2}</div>
              <div className="accordion-elements row mt-5">
                <div class="accordion-element col-6">
                  <div class="row mb-3 li_element">
                    <div className="col-2 services_icon_div text-center d-flex align-items-center">
                      <img
                        className="services_icon"
                        src={li_1_icon}
                        alt="services_icon"
                      />
                    </div>
                    <div class="col-10 ac_li_element">
                      <p>{li_1}</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-element col-6">
                  <div class="row mb-3 li_element">
                    <div class="col-2 services_icon_div text-center d-flex align-items-center">
                      <img className="services_icon" src={li_2_icon} alt="" />
                    </div>
                    <div class="col-10 ac_li_element">
                      <p>{li_2}</p>
                    </div>
                  </div>
                </div>{" "}
                <div class="accordion-element col-6">
                  <div class="row mb-3 li_element">
                    <div class="col-2 services_icon_div text-center d-flex align-items-center">
                      <img className="services_icon" src={li_3_icon} alt="" />
                    </div>
                    <div class="col-10 ac_li_element">
                      <p>{li_3}</p>
                    </div>
                  </div>
                </div>{" "}
                <div class="accordion-element col-6">
                  <div class="row mb-3 li_element">
                    <div class="col-2 services_icon_div text-center d-flex align-items-center">
                      <img className="services_icon" src={li_4_icon} alt="" />
                    </div>
                    <div class="col-10 ac_li_element">
                      <p>{li_4}</p>
                    </div>
                  </div>
                </div>
                        <div class="accordion-element col-6">
                  <div class="row mb-3 li_element">
                    <div class="col-2 services_icon_div  text-center d-flex align-items-center">
                      <img className="services_icon" src={li_5_icon} alt="" />
                    </div>
                    <div class="col-10 ac_li_element">
                      <p>{li_5}</p>
                    </div>
                  </div>
                </div>
                {li_6.length > 0 ? (
                  <div class="accordion-element col-6">
                    <div class="row mb-3 li_element">
                      <div class="col-2 services_icon_div  text-center d-flex align-items-center">
                        <img className="services_icon" src={li_6_icon} alt="" />
                      </div>

                      <div class="col-10 ac_li_element">
                        <p>{li_6}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                 <button className="startNow_button  btn_second">
              <a
                href="https://forms.gle/K9M9cUf4iwADNES97"
                target="_blank"
                rel="noreferrer"
              >
                {data["accordion_startNow_btn"]}
              </a>
            </button>
              </div>
            </div>
            <div className="bottom_div">
              {/* accordion-img */}
              <img src={img} alt={img_alt} />
            </div>
          </div>
          {/* <div> */}
           {/*  <button className="startNow_button">
              <a
                href="https://forms.gle/K9M9cUf4iwADNES97"
                target="_blank"
                rel="noreferrer"
              >
                {data["accordion_startNow_btn"]}
              </a>
            </button> */}
          {/* </div> */}
        </div>
      </div>
    );
  }
  /* function YourComponent() { */
  return (
    <>
    <div id="nonVisible" className="container">
      <div className="accordion" id="accordionExample">
        <AccordionItem
          id="1"
          main_icon={data["accordion_1_main_icon"]}
          title={data["accordion_1_title"]}
          img={data["accordion_1_img"]}
          img_alt={data["accordion_1_img_alt"]}
          content_1={data["accordion_1_content_1"]}
          content_2={data["accordion_1_content_2"]}
          li_1={data["accordion_1_li_1"]}
          li_1_icon={data["accordion_1_li_1_icon"]}
          li_2={data["accordion_1_li_2"]}
          li_2_icon={data["accordion_1_li_2_icon"]}
          li_3={data["accordion_1_li_3"]}
          li_3_icon={data["accordion_1_li_3_icon"]}
          li_4={data["accordion_1_li_4"]}
          li_4_icon={data["accordion_1_li_4_icon"]}
          li_5={data["accordion_1_li_5"]}
          li_5_icon={data["accordion_1_li_5_icon"]}
          li_6={data["accordion_1_li_6"]}
          li_6_icon={data["accordion_1_li_6_icon"]}
        />
        <AccordionItemSecond
          id="2"
          main_icon={data["accordion_2_main_icon"]}
          title={data["accordion_2_title"]}
          img={data["accordion_2_img"]}
          img_alt={data["accordion_2_img_alt"]}
          content_1={data["accordion_2_content_1"]}
          content_2={data["accordion_2_content_2"]}
          li_1={data["accordion_2_li_1"]}
          li_1_icon={data["accordion_2_li_1_icon"]}
          li_2={data["accordion_2_li_2"]}
          li_2_icon={data["accordion_2_li_2_icon"]}
          li_3={data["accordion_2_li_3"]}
          li_3_icon={data["accordion_2_li_3_icon"]}
          li_4={data["accordion_2_li_4"]}
          li_4_icon={data["accordion_2_li_4_icon"]}
          li_5={data["accordion_2_li_5"]}
          li_5_icon={data["accordion_2_li_5_icon"]}
          li_6={data["accordion_2_li_6"]}
          li_6_icon={data["accordion_2_li_6_icon"]}
          />
        <AccordionItem
          id="3"
          main_icon={data["accordion_3_main_icon"]}
          title={data["accordion_3_title"]}
          img={data["accordion_3_img"]}
          img_alt={data["accordion_3_img_alt"]}
          content_1={data["accordion_3_content_1"]}
          content_2={data["accordion_3_content_2"]}
          li_1={data["accordion_3_li_1"]}
          li_1_icon={data["accordion_3_li_1_icon"]}
          li_2={data["accordion_3_li_2"]}
          li_2_icon={data["accordion_3_li_2_icon"]}
          li_3={data["accordion_3_li_3"]}
          li_3_icon={data["accordion_3_li_3_icon"]}
          li_4={data["accordion_3_li_4"]}
          li_4_icon={data["accordion_3_li_4_icon"]}
          li_5={data["accordion_3_li_5"]}
          li_5_icon={data["accordion_3_li_5_icon"]}
          li_6={data["accordion_3_li_6"]}
          li_6_icon={data["accordion_3_li_6_icon"]}
        />
        <AccordionItemSecond
          id="4"
          main_icon={data["accordion_4_main_icon"]}
          title={data["accordion_4_title"]}
          img={data["accordion_4_img"]}
          img_alt={data["accordion_4_img_alt"]}
          content_1={data["accordion_4_content_1"]}
          content_2={data["accordion_4_content_2"]}
          li_1={data["accordion_4_li_1"]}
          li_1_icon={data["accordion_4_li_1_icon"]}
          li_2={data["accordion_4_li_2"]}
          li_2_icon={data["accordion_4_li_2_icon"]}
          li_3={data["accordion_4_li_3"]}
          li_3_icon={data["accordion_4_li_3_icon"]}
          li_4={data["accordion_4_li_4"]}
          li_4_icon={data["accordion_4_li_4_icon"]}
          li_5={data["accordion_4_li_5"]}
          li_5_icon={data["accordion_4_li_5_icon"]}
          li_6={data["accordion_4_li_6"]}
          li_6_icon={data["accordion_4_li_6_icon"]}
        />
        <AccordionItem
          id="5"
          main_icon={data["accordion_5_main_icon"]}
          title={data["accordion_5_title"]}
          img={data["accordion_5_img"]}
          img_alt={data["accordion_5_img_alt"]}
          content_1={data["accordion_5_content_1"]}
          content_2={data["accordion_5_content_2"]}
          li_1={data["accordion_5_li_1"]}
          li_1_icon={data["accordion_5_li_1_icon"]}
          li_2={data["accordion_5_li_2"]}
          li_2_icon={data["accordion_5_li_2_icon"]}
          li_3={data["accordion_5_li_3"]}
          li_3_icon={data["accordion_5_li_3_icon"]}
          li_4={data["accordion_5_li_4"]}
          li_4_icon={data["accordion_5_li_4_icon"]}
          li_5={data["accordion_5_li_5"]}
          li_5_icon={data["accordion_5_li_5_icon"]}
          li_6={data["accordion_5_li_6"]}
          li_6_icon={data["accordion_5_li_6_icon"]}
        />
      </div>
    </div>
      <Footer />
          </>

  );
};
// }

export default Accordion;
