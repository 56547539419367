// import React, { useEffect } from 'react'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/home/style.css";
import "../../assets/css/home/function.css";

import Layout from "../../layout";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const Home = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isClipPathLarger, setIsClipPathLarger] = useState(false);
  const [isLocated, setIsLocated] = useState();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, 1);
    // window.location.reload()
  }, []);

  useEffect(() => {
    // Set up an interval to run the checker function every half second (500 milliseconds)
    setInterval(checker, 2000);
    function checker() {
      const element = document.getElementById("colorful_finish_animation");
      if (element) {
        const checker = getComputedStyle(element).getPropertyValue("clip-path");
        setIsLocated(checker);
        // console.log(checker)
      }
    }

    // To stop the interval (e.g., when you no longer need it)
    // clearInterval(intervalId);
    const handleScroll = () => {
      if (window.scrollY) {
        // const largerClipPath = "polygon(45% 20%, 55% 20%, 100% 100%, 0% 100%)";
        const largerClipPath = "polygon(46% 10%, 55% 10%, 100% 100%, 0% 100%)";
        // Check if the current clip-path is larger than the known larger clip-path
        const isLarger = isLocated >= largerClipPath;
        document.getElementById("colorful_finish_animation");

        setIsScrolled(true);
        setIsClipPathLarger(isLarger);
      } else {
        setIsScrolled(false);
        setIsClipPathLarger(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  if (isScrolled) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "scroll";
  }

  /* checks if the scrollY is bottom  */
  const navigate = useNavigate();

  // const [isAtBottom, setIsAtBottom] = useState(false);

  // useEffect(() => {
  //   function handleScroll_finish(event) {
  //     const windowHeight = window.innerHeight;
  //     const documentHeight = document.documentElement.scrollHeight;
  //     const scrollPosition = window.scrollY;

  //     // Check if the user is at the bottom of the page
  //     if (scrollPosition + windowHeight >= documentHeight) {
  //       // setIsAtBottom(true);
  //       // i++;
  //     } else {
  //       // setIsAtBottom(false);
  //     }
  //     // console.log(i)
  //     // deltaY
  //     console.log(event)
  //   }

  //   window.addEventListener('mousewheel', handleScroll_finish);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('mousewheel', handleScroll_finish);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (isAtBottom) {
  //     // Add your class when at the bottom
  //     const element = document.getElementById("colorful_finish_animation");
  //     element.classList.add("colorful_finish_animation");
  //     // For example, navigate to another route:
  //     // setTimeout(navigate, 2500)
  //     // navigate('/services');
  //     /* function navigate() {
  //     } */

  //   }
  // }, [isAtBottom]);

  function navigateTo() {
    if (navigateTo) {
      const black = document.getElementById("black_finish_animation");
      black.classList.add("black_finish_animation");

      const content = document.getElementById("regular_texts_hide");
      content.classList.add("regular_texts_hide");

      const element = document.getElementById("colorful_finish_animation");
      element.classList.add("colorful_finish_animation");

      /* const element_phone = document.getElementById(
        "colorful_finish_animation_phone"
      );
      element_phone.classList.add("colorful_finish_animation_phone"); */

      setTimeout(() => {
        // Navigate to another page
        navigate("/services");
        document.body.style.overflow = "scroll";
      }, 2500);
    }
  }

  return (
    <Layout>
      <section
        className={"home d-flex position-relative justify-content-center"}
      >
        <div
          id="colorful_finish_animation"
          className={`${
            isScrolled
              ? isClipPathLarger
                ? "colorful zoomIn light_center_right"
                : "colorful zoomIn light_center_left"
              : "colorful colorful_animation"
          }`}
        ></div>
        <div
          id="black_finish_animation"
          className={`${isScrolled ? "zoomIn bw-image" : "bw-image"}`}
        ></div>

        <img
          className={isScrolled ? "avize avize_out avize_center" : "avize"}
          src="/assets/img/home/avize.png"
          alt="osiads avize"
        />

        {/* <img
          id="colorful_finish_animation"
          className={`${
            isScrolled
              ? isClipPathLarger
                ? "colorful zoomIn light_center_right"
                : "colorful zoomIn light_center_left"
              : "colorful colorful_animation"
          }`}
          src="/assets/img/home/colorful.png"
          alt="asiads colorful background"
        />

        <img
          id="black_finish_animation"
          className={`${isScrolled ? "zoomIn bw-image" : "bw-image"}`}
          src="/assets/img/home/black.png"
          alt="osiads black background"
        /> */}
        {/* IMAGE ON TABLET SCREEN */}

        {/*  <img
          className={`${isScrolled ? "zoomIn bw-tablet" : "bw-tablet"}`}
          src="/assets/img/home/colorful_tablet_.png"
          alt="osiads home background"
        />
        <img
          className={`${isScrolled ? "zoomIn bw-tablet" : "bw-tablet"}`}
          src="/assets/img/home/black_tablet_.png"
          alt="osiads home background"
        /> */}

        {/* IMAGE ON TABLET SCREEN */}

        {/* IMAGE ON PHONE SCREEN */}
        {/* <img
          id="colorful_finish_animation_phone"
          className={`${
            isScrolled
              ? isClipPathLarger
                ? "colorful_phone zoomIn light_phone_center_right"
                : "colorful_phone zoomIn light_phone_center_left"
              : "colorful_phone colorful_phone_animation"
          }`}
          src="/assets/img/home/colorful_phone.svg"
          alt="osiads home background"
        />

        <img
          className={`${isScrolled ? "zoomIn " : ""}black_phone`}
          src="/assets/img/home/black_phone.svg"
          alt="osiads black background"
        /> */}

        {/* <div
          id="colorful_finish_animation_phone"
          className={`${
            isScrolled
              ? isClipPathLarger
                ? "colorful_phone zoomIn light_phone_center_right"
                : "colorful_phone zoomIn light_phone_center_left"
              : "colorful_phone colorful_phone_animation"
          }`}
        ></div>
        <div
          id="black_finish_animation"
          className={`${isScrolled ? "zoomIn " : ""}black_phone`}
        ></div> */}
        {/* IMAGE ON PHONE SCREEN */}

        <div
          id="regular_texts_hide"
          class={
            isScrolled ? "regular_texts regular_texts_show" : "regular_texts"
          }
        >
          <h2 className="text_1">Görünün</h2>
          <h2 className="text_2">Ön Plana Çıkın</h2>
          <h2 className="text_3">Bulunabilir Olun</h2>
          <br />
          <br />
          <br />
          <button disabled={!isScrolled}  onClick={navigateTo} className="arrow">
            <img src="/assets/img/home/arrow.gif" alt="osiads arrow" />
          </button>
        </div>
      </section>
    </Layout>
  );
};

export default Home;
