import React from 'react'
import Layout from '../../layout'
import AboutSection from '../../components/about'

const About = () => {
  return (
    <Layout>
        <AboutSection/>
    </Layout>
  )
}

export default About